.ujfm-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-height: 440px;
}

.left-side {
  flex: 1;
  text-align: left;
}

.leftSideText {
  font-size: 50px;
  margin-left: 40px;
  margin-right: 20px;
  line-height: 1.2;
  letter-spacing: 0.03em;
}

.leftSideTextPart1 {
  font-family: OutfitMedium, sans-serif;
  font-weight: 600;
  color: var(--text-color);
}

.leftSideTextPart2 {
  font-family: OutfitMedium, sans-serif;
  font-weight: 600;
  color: #1ed760;
}

.leftSideTextPart3 {
  font-family: OutfitMedium, sans-serif;
  font-weight: 600;
  color: var(--text-color);
}

.leftSideTextPart4 {
  font-family: OutfitMedium, sans-serif;
  font-weight: 600;
  color: #56ccf2;
}

.right-side {
  flex: 1;
  text-align: center;
  margin-right: 20px;
}

.rightSideText1 {
  font-size: 24px;
  margin-left: 20px;
  margin-right: 20px;
  line-height: normal;
  letter-spacing: 0.03em;
}

.rightSideText2 {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  line-height: normal;
}

.text-section h1, .text-section h2 {
  font-weight: bold;
}

.highlight-green {
  color: #1DB954;
}

.highlight-blue {
  color: #1DA1F2;
}

.qr-code {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.modalDesktopAppstore {
  width: 135px;
  height: 40px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-appstore);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.desktop-link {
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #333333;
  font-size: 14px;
}

.desktop-link a {
  text-decoration: none;
  color: #333333;
  font-family: "OutfitRegular", serif;
}

.contact-us {
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 10px;
  text-decoration: none;
  color: var(--sub-text-color);
  font-family: "OutfitRegular", serif;
}

.email-icon {
  margin-left: 10px;
  width: 16px;
  height: 12px;
  margin-top: -4px;
}

.desktop-site-icon {
  color: var(--sub-text-color);
  margin-left: 10px;
  width: 10px;
  height: 20px;
  margin-top: -4px;
}
