.searchSongTitle {
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
  width: 100%;
  font-family: OutfitBold, serif;
  margin: 20px auto auto;
  color: var(--text-color);
}

.searchSongSubtitle {
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
  width: 100%;
  font-family: OutfitBold, sans-serif, serif;
  font-size: 12px;
  margin: 50px auto auto;
  color: var(--text-color);
}

.inputSearchSongParent {
  text-align: center;
  margin-top: 30px;
  padding: 0 20px;
}
