.lstTitle {
  margin-top: 30px;
  padding: 0 15px;
}

.lstSong {
  margin-top: 20px;
  margin-bottom: 80px;
}

.lstSong ul {
  padding-left: 10px;
  padding-right: 10px;
}
