@font-face {
  font-family: OutfitRegular;
  src: url(./../assets/font/Outfit-Regular.ttf) format("truetype");
}

@font-face {
  font-family: OutfitMedium;
  src: url(./../assets/font/Outfit-Medium.ttf) format("truetype");
}

@font-face {
  font-family: OutfitBold;
  src: url(./../assets/font/Outfit-Bold.ttf) format("truetype");
}

@import "standard";
@import "desktopModal";
@import "searchSongModal";

body {
  --page-bkg-color: #fff;
  --text-color: #262f31;
  --sub-text-color: #909090;
  --text-modal-noticed-color: #fff;
  --bkg-color: #fff;
  --modal-bkg-color: #fff;
  --btn-txt-color: #000;
  --btn-bg-color: rgb(11, 88, 160);
  --input-border-color: #dddddd;
  --btn-qrcode-color: #f3f3f3;
  --img-logo: url(/assets/img/ic_logo.png);
  --img-venue: url(/assets/img/ic_location.png);
  --img-location-pin: url(/assets/img/ic_pin_fill.png);
  --img-share-qrcode: url(/assets/img/ic_qrcode.png);
  --img-question-mark: url(/assets/img/ic_question.png);
  --img-back: url(/assets/img/ic_back.png);
  --img-close: url(/assets/img/ic_close.png);
  --img-appstore: url(/assets/img/ic_appstore.png);
  --img-arrow-left: url(/assets/img/ic_arrow_left_b.png);
  --img-arrow-right: url(/assets/img/ic_arrow_right_b.png);
}

body.dark-theme {
  --page-bkg-color: #111111;
  --text-color: #fff;
  --sub-text-color: #909090;
  --text-modal-noticed-color: #909090;
  --bkg-color: #303032;
  --modal-bkg-color: #1c1c1e;
  --btn-txt-color: rgb(46, 43, 43);
  --btn-bg-color: rgb(20, 211, 195);
  --input-border-color: #6e6e6e;
  --btn-qrcode-color: #6e6e6e;
  --img-logo: url(/assets/img/ic_logo_dark.png);
  --img-venue: url(/assets/img/ic_location_dark.png);
  --img-location-pin: url(/assets/img/ic_pin_fill_dark.png);
  --img-share-qrcode: url(/assets/img/ic_qrcode_dark.png);
  --img-question-mark: url(/assets/img/ic_question_dark.png);
  --img-back: url(/assets/img/ic_back_dark.png);
  --img-close: url(/assets/img/ic_close_dark.png);
  --img-appstore: url(/assets/img/ic_appstore_dark.png);
  --img-arrow-left: url(/assets/img/ic_arrow_left_w.png);
  --img-arrow-right: url(/assets/img/ic_arrow_right_w.png);
}

html * {
  font-family: OutfitRegular, serif;
}

body {
  overflow-x: hidden;
  background-color: var(--page-bkg-color);
  margin: 0;
}

.noScroll {
  height: 100px;
  min-height: 100px;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

.hide {
  display: none;
}

.topVenue {
  height: 34px;
  margin-top: 12px;
  background-color: var(--page-bkg-color);
}

.imgLogoTop {
  width: auto;
  height: 20px;
  width: 50px;
  margin-top: 6px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-logo);
  background-size: cover;
}

.venuePrefixSpan {
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-venue);
  background-size: cover;
}

.venueSpan {
  font-size: 14px;
  font-family: "OutfitBold";
  color: var(--text-color);
  align-content: center;
  display: block;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrongLocationSpan {
  font-size: 14px;
  color: #53b1c0;
  font-family: "OutfitBold";
}

.wrongLocaltion {
  width: Auto;
  height: 34px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestBoldSpan {
  height: 100%;
  font-size: 18px;
  margin-left: 5px;
  margin-right: 4px;
  margin-top: 5px;
  font-family: "OutfitBold", sans-serif;
  color: var(--text-color);
  align-content: center;
}

.requestBoldSpanName {
  height: 100%;
  font-size: 18px;
  margin-left: 0;
  margin-right: 4px;
  margin-top: 5px;
  font-family: "OutfitBold", sans-serif;
  color: var(--text-color);
  align-content: center;
}

.requestedSpan {
  height: 100%;
  font-size: 18px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 5px;
  align-items: center;
  color: #262f31;
}

.bulletSpan {
  font-size: 18px;
  font-family: "OutfitBold", serif;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 5px;
  color: #262f31;
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.requestLinkSpan {
  height: 100%;
  font-size: 18px;
  margin-left: 6px;
  margin-right: 4px;
  margin-top: 5px;
  font-family: "OutfitMedium", sans-serif;
  color: var(--text-color);
  text-decoration: underline;
  align-content: center;
}

.noPadding {
  padding-left: 0;
  padding-right: 0;
}

.noMargin {
  margin-left: 0;
  margin-right: 0;
}

.person {
  color: #fff;
  width: 100%;
  height: 180px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.person1 {
  color: #fff;
  padding-bottom: 10px;
  position: relative;
}

.background {
  padding-top: 10px;
}

#venue {
  position: absolute;
  right: 0;
  top: 20px;
  height: 60px;
  color: white;
  font-size: 13px;
  margin-right: 20px;
}

#imgVenue {
  height: 14px !important;
  width: 11px !important;
  margin-top: 4px;
  margin-left: 6px;
}

.center {
  top: 60px;
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.centerAMZ {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.name {
  font-size: 16px;
  font-family: "OutfitMedium";
  color: var(--text-color);
}

.songTitle {
  font-size: 16px;
  color: var(--text-color);
  font-family: "OutfitBold";
  width: 100%;
  margin-top: 20px;
}

.songArtist {
  font-size: 14px;
  color: #909090;
  font-family: "OutfitRegular";
}

.songRequester {
  font-size: 14px;
  color: #919191;
  font-family: "OutfitBold";
}

.money {
  font-size: 13px;
  font-family: "OutfitBold";
}

.genre {
  font-size: 14px;
}

.address {
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-family: "OutfitBold";
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-img {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.profile-img img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.intro {
  padding: 0 20px;
  margin-top: 30px;
  text-align: center;
  background: #fff;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto, serif;
  font-size: 15px;
  font-weight: 300;
  padding: 0 11px 0 13px;
  z-index: 1000;
  text-overflow: ellipsis;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 15px;
  -webkit-appearance: none;
}

.phoneInput {
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 15px;
  -webkit-appearance: none;
}

#btnCloseLocation {
  margin-top: 10px;
}

.pac-container {
  z-index: 2000;
}

.noLocation {
  margin: 0 auto;
  padding-top: 20px;
}

.imageNoLocation {
  text-align: center;
  margin-top: 90px;
}

.imageNoLocation img {
  width: 45%;
  height: 20%;
}

.accept {
  padding-top: 25px;
  margin: 0 auto;
  text-align: center;
}

.accept span {
  padding: 14px 40px;
  border: 1px solid #1111113d;
  color: #fff;
  font-family: "OutfitBold";
  background-color: #111;
  border-radius: 25px;
}

.accept span:hover {
  cursor: pointer;
}

.textAllow {
  text-align: center;
}

.selectPlaylist {
  width: 100px;
  margin-top: 10px;
  font-size: 22px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: var(--text-color);
}

.songTitle {
  font-size: 14px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: var(--text-color);
}

.songThumb {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
  align-content: center;
}

.songThumb img {
  width: 50px;
  height: 50px;
  // border-radius: 10%;
}

.songThumbSearch {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
  align-content: center;
}

.songThumbSearch img {
  width: 50px;
  height: 50px;
  // border-radius: 10%;
}

.songThumbMarquee {
  width: 40px;
  height: 40px;
  border-radius: 10%;
}

.song {
  background-repeat: no-repeat;
  height: 70px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  z-index: 100;
  color: var(--bkg-color);
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.songFirstFriday {
  background-repeat: no-repeat;
  height: 90px;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  // color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.songSpotifySearch {
  background-repeat: no-repeat;
  height: 70px;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  color: var(--bkg-color);
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.songRequest {
  background-repeat: no-repeat;
  height: 70px;
  width: 100%;
  margin-left: 5px;
  overflow: hidden;
  border-radius: 16px;

  align-items: center;
  align-content: center;
  color: #fff;
}

.songRequestThumb {
  width: 36px;
  height: 36px;
  margin-left: 10px;
  align-items: center;
  align-content: center;
}

.songRequestThumb img {
  width: 36px;
  height: 36px;
  border-radius: 25%;
}

.playlist {
  background-repeat: no-repeat;
  height: 135px;
  width: 110px;
  overflow: hidden;
  text-align: center;
  border-radius: 16px;
  border: 4px solid #ffffff00;
  color: #fff;
  position: relative;
}

.playlistActive {
  background-repeat: no-repeat;
  height: 135px;
  width: 110px;
  overflow: hidden;
  text-align: center;
  border-radius: 16px;
  border: 3px solid #53b1c0;
  color: #fff;
  position: relative;
}

.imgPlaylist,
.divPlaylist {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.divPlaylist {
  z-index: 5;
}

.txtPlaylist {
  width: 100%;
  position: absolute;
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  display: flex;
  align-items: center;
  top: 0;
  object-fit: cover;
  left: 0;
  color: #fff;
  z-index: 10;
}

.txtPlaylist span {
  position: absolute;
  left: 12px;
  right: 8px;
  text-align: start;
  font-size: 16px;
  font-family: "OutfitMedium";
  color: #fff;
}

.footer {
  width: 100%;
  height: 100px;
  background-color: var(--bkg-color);
  position: fixed;
  bottom: 0;
}

.footerUpdating {
  width: 100%;
  height: 50px;
  background-color: var(--bkg-color);
  position: fixed;
  bottom: 0;
}

.footerFF {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.fabTip {
  bottom: 60px;
  height: 48px;
  color: white;
  text-align: center;
  position: fixed;
}

.fabShare {
  width: 46px;
  height: 46px;
  background-color: #2b2b2b;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgb(184, 184, 184);
  transition: all 0.1s ease-in-out;
  color: white;
  position: fixed;
  right: 14px;
  bottom: 60px;
}

.fabShare img {
  width: 40px;
  height: 40px;
  margin-left: 4px;
  margin-top: 2px;
}

.fab:hover {
  box-shadow: 0 6px 14px 0 #666;
  transform: scale(1.05);
}

.song {
  margin-left: 0;
  margin-right: 0;
}

.textarea {
  width: 100%;
  height: 120px;
  padding: 4px;
  background-color: #fff;
  border-color: #1e212931;
  border-radius: 0.4em;
  color: #363636;
}

.textareaName {
  width: 100%;
  height: 40px;
  padding: 4px;
  background-color: transparent;
  border-color: var(--input-border-color);
  border-radius: 0.4em;
  color: var(--text-color);
}

.btnRequestShoutout {
  font-family: "OutfitBold", serif;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #0092e0;
  color: #fff;
  width: 160px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 #0092e0;
}

.btnViewPlaylist {
  font-family: "OutfitBold", serif;
  font-size: 16px;
  margin-right: 10px;
  background: linear-gradient(97.06deg, #63CF61 -14.57%, #42B140 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.btnPickMore {
  font-family: "OutfitBold", serif;
  font-size: 16px;
  margin-left: 10px;
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.btnNotify {
  font-family: "OutfitBold", serif;
  font-size: 16px;
  margin: 15px 20px 20px;
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.btnEmail {
  font-family: "OutfitBold", serif;
  font-size: 16px;
  margin-left: 10px;
  padding-left: 0px;
  padding-right: 0px;
  background: linear-gradient(99.72deg, #ffb84e -3.4%, #fea623 103.88%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.btnPhoneNumber {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-right: 10px;
  padding-left: 0px;
  padding-right: 0px;
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.searchNotes {
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.03;
  letter-spacing: normal;
  text-align: left;
  color: #55c053;
}

.btnCancelSongRequest {
  margin-top: 15px;
  background-color: #ffffff00;
  color: #1e2129;
  width: 160px;
  opacity: 0.35;
  height: 40px;
}

.btnCancelRequest {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #f3f3f3;
  color: #000;
  width: 100%;
  height: 44px;
  border-radius: 8px;
}

.btnMaybeLater {
  margin-top: 20px;
  background-color: #ffffff00;
  color: #909090;
  width: 100%;
  height: 30px;
  font-size: 14px;
}

.btnUsernameSkip {
  background-color: #ffffff00;
  color: #000;
  width: 200px;
  height: 30px;
  margin-bottom: 12px;
  font-size: 12px;
}

.btnGetCoin {
  font-family: "OutfitBold";
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #0092e0;
  color: #fff;
  width: 280px;
  height: 40px;
  font-size: 13px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 #0092e0;
}

.btnCoinText {
  font-family: "OutfitBold";
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #2080c9;
  color: #fff;
  width: 280px;
  height: 40px;
  font-size: 13px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 #2080c977;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--modal-bkg-color);
  background-clip: padding-box;
  border-radius: 1rem;
  outline: 0;
}

.modal-body-custom {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}

.songThumbRequested {
  width: 200px;
  height: 200px;
  margin-top: 10px;
  align-items: center;
  align-content: center;
}

.songThumbRequested img {
  width: 200px;
  height: 200px;
  box-shadow: 0 6px 10px 0 rgb(184, 184, 184);
  // border-radius: 10%;
}

.djAvatar {
  background-image: url("/assets/img/ic_default_user.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
  object-fit: none;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
}

.djAvatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.progressTime bar {
  background-color: #0092e0;
}

.marginTop {
  margin-top: 20px;
}

.fullWidth {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.tipBlock {
  width: 100%;
  margin-top: 20px;
}

.tipItem {
  margin-left: 0;
  margin-right: 0;
  height: 140px;
  width: 100%;
}

.rewardItem {
  margin-left: 0;
  margin-right: 0;
  height: 140px;
  width: 90px;
}

.rewardItem img {
  height: 70px;
  width: 70px;
}

.donateItem {
  margin-left: 0;
  margin-right: 0;
  height: 140px;
  width: 100%;
}

.moneyText {
  font-size: 16px;
  font-family: "OutfitRegular";
  color: #53b1c0;
}

.resetMargin {
  margin-left: 0;
  margin-right: 0;
}

.songSearch {
  width: 40px;
  height: 40px;
}

.songSearch img {
  width: 26px;
  height: 26px;
  right: 20px;
  position: absolute;
  margin-top: 3px;
}

.songSearchResult {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 400px;
  width: 100%;
}

.songSearchResultList {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.songSearchSuggest {
  margin-top: 15px;
  margin-bottom: 10px;
  height: 500px;
  width: 100%;
}

.songSearchSuggestList {
  margin-top: 10px;
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.songPremiumSuggestList {
  height: 340px;
  overflow: hidden;
  overflow-y: scroll;
}

.songSearchDisclaimer {
  width: 100%;
  color: gray;
  margin: 10px;
  font-size: 15px;
}

.popupTitle {
  margin-left: 20px;
  margin-right: 20px;
  font-family: "OutfitBold", serif;
  margin-top: 20px;
  color: var(--text-color);
}

.popupTxtTitle {
  margin-left: 20px;
  width: 100%;
  font-family: "OutfitBold", serif;
  margin-top: 20px;
}

div.horizontal {
  display: flex;
  width: 100%;
  height: 170px;
  overflow: auto;
  flex-wrap: nowrap;
}

div.horizontal .table {
  display: table;
  table-layout: fixed;
  width: 110px;
}

div.horizontal .table article {
  width: 110px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.btnShare {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 30px;
}

.btnShare fa-icon {
  color: #fff;
}

hr.solid {
  width: 100%;
  border-top: 1px solid #bbb;
}

.loginBtn {
  width: 100%;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 20px;
  display: inline-block;
  font-size: 15px;
  line-height: 40px;
}

.fb {
  background-color: #3b5998;
  color: white;
}

.google {
  background-color: #dd4b39;
  color: white;
}

.sms {
  background-color: #2080c9;
  color: white;
}

#marquee {
  position: relative;
  width: 100%;
  height: 50px;
  background: transparent;
  overflow: hidden;
}

#marquee::before {
  content: " ";
  white-space: pre;
}

#marqueeP {
  position: absolute;
  top: 0;
  margin: 0;
  white-space: nowrap;
  color: var(--text-color);
  margin-top: 4px;
  animation: sidescroll 120s linear infinite;
}

@keyframes sidescroll {
  0% {
    left: 100%;
    transform: translateX(0);
  }

  100% {
    left: 0;
    transform: translateX(-100%);
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center-crop {
  object-fit: cover;
}

.user-maybe-later {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
}

.sponsor-banner {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.sponsor-banner img {
  width: 100%;
  height: 160px;
  border: none;
  border-radius: 14px;
  display: inline-block;
}

.coin-text {
  font-size: 14px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: right;
  color: #36ce87;
}

#apple-pay-button {
  height: 48px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
}

.menu-modal {
  position: absolute;
  top: 10px;
  width: 200px;
  height: 200px;
  bottom: 0;
  right: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
}

.menu-firstfriday-modal {
  position: absolute;
  top: 10px;
  width: 200px;
  height: 180px;
  bottom: 0;
  right: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto;
}

.modal-custom-lg .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
}

.modal-custom-lg .modal-content {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
}

.budlight-modal {
  background-image: url("/assets/img/img_budlight_new_bg.jpg");
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.budlightContent {
  height: 380px;
  background-color: #ffffffcc;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
}

.btnBudlightText {
  margin-top: 30px;
  background-color: #23559c;
  color: white;
  width: 160px;
  height: 60px;
  font-family: "OutfitBold";
  border: solid 1px #ffffff;
  border-radius: 20px;
}

.budlight-center {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.budlight-end {
  display: flex;
  justify-content: flex-end;
}

.btnBudlightMaybeLater {
  margin-top: 10px;
  background-color: #ffffff00;
  color: #23559cb6;
  width: 120px;
  height: 30px;
  font-size: 12px;
  font-family: "OutfitBold";
}

.item-footer-info {
  width: 60px;
  color: gray;
}

.mainItem {
  background-repeat: no-repeat;
  height: 100px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.mainItemKaraoke {
  background-repeat: no-repeat;
  height: 250px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.mainTitle {
  font-size: 16px;
  color: #000000;
  display: block;
  text-align: left;
  font-family: "OutfitBold";
}

.mainSubtitle {
  font-size: 12px;
  color: #919191;
  display: block;
  text-align: left;
}

.mainThumb {
  width: 60px;
  height: 60px;
  margin-left: 20px;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.mainThumb img {
  width: 60px;
  height: 60px;
}

.mainTitleKaraoke {
  font-size: 20px;
  color: #000000;
  display: block;
  text-align: center;
  font-family: "OutfitBold";
}

.mainSubtitleKaraoke {
  font-size: 16px;
  color: #919191;
  display: block;
  text-align: center;
}

.mainThumbKaraoke {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  align-content: center;
}

.mainThumbKaraoke img {
  width: 60px;
  height: 60px;
}

.btnTip {
  background: linear-gradient(99.72deg, #ffb84e -3.4%, #fea623 103.88%);
  border-radius: 8px;
  align-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  min-width: 40px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 4px;
}

.btnTip img {
  width: 36px;
  height: 36px;
}

.btnTip span {
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold";
}

.btnMenu {
  border-radius: 8px;
  align-content: center;
  align-items: center;
  min-width: 40px;
  padding-left: 0px;
  padding-right: 10px;
  color: var(--text-color);
  font-family: "OutfitMedium";
  font-size: 16px;
  text-align: left;
  height: 44px;
  margin-left: 15px;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.btnMenu:focus {
  color: var(--text-color);
  outline: 0 !important;
  box-shadow: none;
  background-color: transparent;
}

.btnMenu img {
  width: 32px;
  height: 32px;
}

.btnSpotifyHeader {
  border-radius: 8px;
  align-content: center;
  align-items: center;
  min-width: 30px;
  text-align: left;
  height: 30px;
}

.btnSpotifyHeader img {
  width: 26px;
  height: 26px;
}

.btnSpotifyTicker {
  align-content: center;
  align-items: center;
  min-width: 15px;
  text-align: left;
  height: 15px;
  margin-left: -16px;
  margin-bottom: -14px;
}

.btnSpotifyTicker img {
  width: 15px;
  height: 15px;
}

.btnFollow {
  background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
  border-radius: 8px;
  align-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  padding-left: 0px;
  padding-right: 0px;
}

.btnFollow img {
  margin-top: 3px;
  width: 32px;
  height: 32px;
}

.btnFollow span {
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold", serif;
}

.txtSearch {
  height: 48px;
  margin-top: 20px;
  left: 0;
  right: 0;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid rgb(153, 148, 148);
  border-radius: 6px;
}

.txtSearch textarea {
  height: 36px;
  margin-left: 10px;
  margin-top: 8px;
  display: table-cell;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.genre {
  background-repeat: no-repeat;
  height: 50px;
  padding-left: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.checkingGenreThumb {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;
}

.premiumAvatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;
}

.bpmSliderTitle {
  font-size: 40px;
  margin-top: 10px;
  font-family: "OutfitBold";
  line-height: 1.61;
  letter-spacing: normal;
  line-height: 56px;
  color: #1e2129;
}

.bpmSliderSubtitle {
  font-size: 16px;
  line-height: 1.61;
  letter-spacing: normal;
  font-family: "OutfitRegular";
  line-height: 24px;
  color: #919191;
}

/*#region BPM Slider*/

.slider {
  -webkit-appearance: none;
  position: absolute;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  right: 0;
  left: 0;
  height: 12px;
  background-image: linear-gradient(270deg, #3298a8 0%, #61d4e7 100%);
  outline: none;
  border-radius: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: 0px 0px 0px 0px white;
  width: 50px;
  height: 50px;
  background-color: transparent;
  background-image: url("/assets/img/ic_slider_thumb.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

/*#endregion */

@media only screen and (max-height: 667px) {
  .fabBPMRequest {
    left: 10px;
    right: 10px;
    bottom: 10px;
    height: 48px;
    background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
    border-radius: 8px;
    color: white;
    align-items: center;
    position: fixed;
    display: flex;
    justify-content: center;
  }

  .adBPMRequest {
    bottom: 80px;
    height: 50px;
    width: 320px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-height: 668px) {
  .fabBPMRequest {
    left: 10px;
    right: 10px;
    bottom: 60px;
    height: 48px;
    background: linear-gradient(97.06deg, #61d4e7 -14.57%, #3298a8 121.02%);
    border-radius: 8px;
    color: white;
    align-items: center;
    position: fixed;
    display: flex;
    justify-content: center;
  }

  .adBPMRequest {
    bottom: 120px;
    height: 50px;
    width: 320px;
    position: fixed;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
  }
}

.fabBPMRequest span {
  height: 48px;
  text-align: center;
  line-height: 24px;
  display: flex;
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold";
  align-items: center;
}

.pd_group {
  background-repeat: no-repeat;
  height: Auto;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 16px;
  color: #fff;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.pd_title {
  width: 100px;
  margin-top: 10px;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #1e2129;
}

.pd_title_genre {
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  margin-top: 10px;
  font-family: "OutfitBold";
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  color: #1e2129;
}

.centerItem {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.locationModal_title {
  font-size: 16px;
  font-family: "OutfitBold";
  color: #000;
}

.locationModal_address {
  font-size: 12px;
  color: #919191;
  display: block;
  text-align: left;
}

.tipModal {
  padding-left: 10px;
  padding-right: 10px;
}

.titleTipModal {
  font-family: "OutfitRegular";
  color: #262f31;
  font-size: 16px;
}

.tipModal h5 {
  margin-top: 10px;
  color: #262f31;
}

.tipModalCustomTip {
  font-size: 16px;
  margin-top: 20px;
  color: #262f31;
  margin-left: 20px;
  font-family: "OutfitMedium";
}

.tipModalCustomTipText {
  height: 44px;
  padding: 4px;
  background-color: #fff;
  border-color: #1e212931;
  border-radius: 0.4em;
  font-size: 16px;
  margin-top: 10px;
  color: #262f31;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "OutfitRegular";
}

.checkingShoutoutRequestModal {
  padding-left: 10px;
  padding-right: 10px;
}

.checkingShoutoutRequestModal h4 {
  font-family: "OutfitBold";
  color: #262f31;
}

.checkingShoutoutRequestModal h6 {
  margin-top: 10px;
  color: #262f31;
}

.checkingShoutoutRequestModalShoutoutMessage {
  font-family: "OutfitBold";
  margin-top: 15px;
}

.genreViewport {
  left: 10px;
  right: 10px;
  position: fixed;
  top: 80px;
  bottom: 30px;
}

.genreItem {
  margin-left: -6px;
  padding-left: 0px;
  padding-right: 0px;
  align-content: center;
  align-items: center;
}

.btnTipCustomTip {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  height: 48px;
  background: linear-gradient(99.72deg, #ffb84e -3.4%, #fea623 103.88%);
  border-radius: 8px;
  align-content: center;
  align-items: center;
  text-align: center;
}

.btnTipCustomTip img {
  width: 48px;
  height: 48px;
}

.btnTipCustomTip span {
  font-size: 16px;
  color: #ffffff;
  font-family: "OutfitBold";
}

.mainViewport {
  left: 10px;
  right: 10px;
  position: fixed;
  top: 85px;
  bottom: 120px;
}

.checkingModal {
  padding-left: 10px;
  padding-right: 10px;
}

.checkingModal h4 {
  width: 100%;
  font-family: "OutfitBold";
}

.checkingModal h6 {
  width: 100%;
  margin-top: 15px;
  font-family: "OutfitRegular";
}

.checkingModal span {
  width: 100%;
  font-family: "OutfitBold";
  margin-top: 15px;
}

.askLocationModal {
  padding-left: 10px;
  padding-right: 10px;
}

.askLocationModal h4 {
  font-family: "OutfitBold";
  color: #262f31;
  text-align: start;
}

.askLocationModal h6 {
  font-family: "OutfitRegular";
  color: #262f31;
  text-align: start;
}

.googleAd {
  width: 300px;
  height: 0px;
}

.googleAd2 {
  width: 320px;
  height: 0px;
}

.btnMenuIcon {
  text-align: left;
  height: 44px;
  margin-left: 15px;
  font-weight: 500;
  width: 100%;
  color: #262f31;
  font-family: "OutfitRegular";
}

.btnHelpRequest {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
  background: #f3f3f3;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.btnFirstPopupRequest {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
  background: #1ed760;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

form.nosubmit {
  border: none;
  padding: 0;
  background-color: var(--bkg-color);
}

input.nosubmit {
  width: 100%;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  display: block;
  padding: 9px 4px 9px 40px;
  color: var(--text-color);
  background-color: transparent;
  background-image: url("/assets/img/ic_search.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 20px;
}

form.inputText {
  border: none;
  padding: 0;
}

input.inputText {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 8px;
  display: block;
  padding: 9px 4px 9px 8px;
  color: var(--text-color);
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 20px;
}

.imgThumbUp {
  width: auto;
  height: 15px;
  padding-right: 14px;
}

.songThumbUpvote {
  width: 130px;
  height: 130px;
  margin-top: 50px;
  align-items: center;
  align-content: center;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.songThumbUpvote img {
  width: 130px;
  height: 130px;
  box-shadow: 0 6px 10px 0 rgb(184, 184, 184);
}

.btnUpvote {
  font-family: "OutfitRegular", serif;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  color: var(--bkg-color);
  border-radius: 12px;
  width: 100%;
  height: 60px;
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.btnUpvote:active,
.btnUpvote:hover,
.btnUpvote:focus {
  color: var(--text-color);
  outline: 0 !important;
  box-shadow: none;
  background: var(--btn-qrcode-color);
}

.btnUpvote img {
  width: auto;
  height: 25px;
  margin-right: 4px;
}

.btnUpvote span {
  color: var(--text-color);
}

.search {
  text-align: center;
  padding: 0px;
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.helpModelBullet {
  font-size: 18px;
  color: #53b1c0;
  font-family: OutfitBold, serif;
}

.helpModelBulletContent {
  font-size: 18px;
  color: #262f31;
}

.helpModelButton {
  font-weight: 600;
  font-family: OutfitRegular, serif;
}

.btnSearch {
  text-align: left;
  padding-left: 10px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--input-border-color);
  width: 100%;
  height: 40px;
  color: #909090;
  font-size: 14px;
  cursor: pointer;
}

.btnSearch i {
  padding-right: 10px;
}

.btnSearch:active {
  background-color: lightgray;
}

.customTip {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border-color: lightgray;
}

.btnStandardPlaylist {
  align-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  min-width: 40px;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 4px;
}

.btnStandardPlaylist img {
  width: 36px;
  height: 36px;
}

.btnLocationContinue {
  margin-left: 20px;
  margin-right: 20px;
  background-image: linear-gradient(270deg, #3298a8 0%, #61d4e7 100%);
  color: #fff;
  width: 100%;
  height: 44px;
  border-radius: 8px;
}

.centerLocation {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
}

.locationResult {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 240px;
  width: 100%;
}

.locationResult ul {
  height: 240px;
  overflow: hidden;
  overflow-y: scroll;
}

.currentLocation {
  background-repeat: no-repeat;
  width: 92%;
  margin-left: 15px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  border-radius: 16px;
  color: var(--bkg-color);
  box-shadow: 0 0 0 100px inset, 0 8px 24px rgba(38, 47, 49, 0.08);
}

.currentLocation span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.btnLocationContinue {
  font-family: "OutfitBold", sans-serif;
  font-size: 16px;
  margin: 15px 15px 20px;
  background: linear-gradient(97.06deg, #1ED760 -14.57%, #1ED760 121.02%);
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.centerImage {
  margin: auto 10px 12px 10px;
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

.centerLocationCheck {
  padding-left: 0;
  padding-right: 0;
  align-content: center;
  text-align: center;
}

.btnSpotifyLogo {
  align-content: center;
  align-items: center;
  text-align: center;
  height: 24px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 15px;
}

.btnSpotifyLogo img {
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.btnSpotifyLogo span {
  color: #909090;
  font-size: 12px;
}

.btnSpotifyMenu {
  height: 40px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.centerVertical {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btnSpotifyMenu img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.carousel-indicators {
  bottom: -50px;
}

.carousel-indicators li {
  width: 6px !important;
  height: 6px !important;
  border-radius: 50% !important;
}

.carousel-indicators li {
  width: 6px !important;
  background-color: transparent;
  border: 2px solid var(--text-color);
  height: 6px !important;
  border-radius: 50% !important;
}

.carousel-indicators .active {
  width: 6px !important;
  background-color: var(--text-color);
  height: 6px !important;
  border-radius: 50% !important;
}

// .carousel-control-next-icon,
// .carousel-control-prev-icon {
//   filter: invert(1);
// }

.carousel-control-prev-icon {
  background-image: var(--img-arrow-left) !important;
}

.carousel-control-next-icon {
  background-image: var(--img-arrow-right) !important;
}

// .right.carousel-control,
// .left.carousel-control {
//   display: none;
// }

.group8776 {
  width: 115px;
  height: 115px;
}

.ellipse105 {
  background-color: #1ed760;
  height: 115px;
  width: 115px;
  border-radius: 50%;
}

.min17sec {
  color: #ffffff;
  line-height: 1;
  text-align: center;
  font-size: 24px;
  font-family: OutfitBold;
  left: 144px;
  top: 30px;
  position: absolute;
  width: 85px;
  height: 90px;
}

#Explicit {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.line {
  height: 1.5px;
  background: repeating-linear-gradient(90deg, #b3b3b3 0 5px, #0000 0 7px);
  /*5px red then 2px transparent -> repeat this!*/
}

.introText {
  font-size: 50px;
  margin-left: 20px;
  margin-right: 40px;
  line-height: 1.2;
}

.introTextPart1 {
  font-family: OutfitBold, sans-serif;
  color: var(--text-color);
}

.introTextPart2 {
  font-family: OutfitBold, sans-serif;
  color: #1ed760;
}

.introTextPart3 {
  font-family: OutfitBold, sans-serif;
  color: var(--text-color);
}

.introTextPart4 {
  font-family: OutfitBold, sans-serif;
  color: #56ccf2;
}

.introHowItWorkBtn {
  width: 40px;
  height: 40px;
  background-color: transparent;
  margin-right: 28px;
}

.introHowItWorkBtn img {
  width: 40px;
  height: 40px;
}

.spanAddressName {
  color: var(--text-color);
  font-weight: 500;
  font-size: 16px;
  margin-right: 50px;
}

.spanAddress {
  color: var(--text-color);
  font-weight: 500;
  font-size: 16px;
  margin-right: 50px;
}

.lstSpanAddressName {
  color: var(--text-color);
  font-weight: 700;
}

.lstIconLocation {
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-location-pin);
  background-size: cover;
  width: 30px;
  height: 30px;
}

.spanSuggestedSong {
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
  color: var(--text-color);
}

.menuIconShareQrCode {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-share-qrcode);
  background-size: cover;
}

.requestModalTitle {
  width: 100%;
  font-weight: bold;
  color: var(--text-color);
}

.requestModalSubtitle {
  width: 100%;
  margin-top: 5px;
  color: var(--text-color);
}

.requestModalSongTitle {
  width: 100%;
  font-weight: bold;
  margin-top: 30px;
  color: var(--text-color);
}

.shareQrCodeBtn {
  font-family: "OutfitBold", serif;
  font-size: 16px;
  margin: 25px 20px 20px;
  background: var(--btn-qrcode-color);
  border-radius: 8px;
  color: var(--text-color);
  width: 100%;
  height: 48px;
}

.shareQrCodeUJFMBtn {
  font-family: "OutfitBold", serif;
  font-size: 16px;
  background: #1ED760;
  margin: 0 25px 20px 20px;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  height: 48px;
}

.helpModalTitle {
  padding-left: 40px;
  padding-right: 40px;
  color: var(--text-color);
  font-weight: 700;
  font-family: OutfitRegular, serif;
  font-size: 24px;
}

.helpModalDes {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  white-space: pre-line;
  color: var(--text-color);
  font-weight: 400;
  font-family: OutfitRegular;
  font-size: 16px;
}

.modalNoticed {
  color: var(--text-modal-noticed-color);
  font-weight: 800;
  font-family: OutfitRegular;
}

.modalIntroQuestion {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-question-mark);
  background-size: cover;
}

.modalCreatePartyTitle {
  font-family: OutfitBold, serif;
  font-size: 30px;
  color: var(--text-color);
}

.modalCreatePartySubtitle {
  font-family: OutfitMedium, serif;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: var(--text-color);
}

.modalCreatePartyBack {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-back);
  background-size: cover;
  pointer-events: none;
  touch-action: none;
}

.modalUpvoteBack {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-close);
  background-size: cover;
  pointer-events: none;
  touch-action: none;
}

.createParty-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 1rem;
  outline: 0;
}

.createParty-modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--modal-bkg-color);
  background-clip: padding-box;
  border-radius: 1rem;
  outline: 0;
}

.modalCreatePartyAppstore {
  width: 100%;
  height: 86px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-appstore);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.imgmodalCreatePartyAppstore {
  content: var(--img-appstore);
  width: 100%;
  height: auto;
}

.btnBack {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  background-color: transparent;
  z-index: 1000;
}

.btnClose {
  width: 50px;
  background-color: transparent;
  z-index: 1000;
  display: block;
  margin: 0px 0 0px auto;
}

.modalCloseBtn {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  display: inline-block;
  background-image: var(--img-close);
  background-size: cover;
  pointer-events: none;
  touch-action: none;
}

textarea:focus,
input:focus {
  outline: 3px solid #555;
}

.btnTapConfirm {
  font-family: "OutfitBold";
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
  background: rgb(97, 212, 231);
  background: linear-gradient(
    90deg,
    rgba(97, 212, 231, 1) 0%,
    rgba(50, 152, 168, 1) 100%
  );
  border-radius: 8px;
  border: 0 solid;
  color: #fff;
  width: 100%;
  height: 48px;
}

.requestModalNote {
  width: 100%;
  margin-top: 5px;
  color: var(--sub-text-color);
  margin-left: 15px;
  margin-right: 15px;
  font-size: 12px;
  text-align: left;
}

.btnBackUpvote {
  width: 50px;
  height: 50px;
  background-color: transparent;
  z-index: 1000;
}

.txtUpdatingPlaylist {
  font-size: 16px;
  font-family: OutfitBold,serif;
  color: var(--sub-text-color);
}

.btnShare {
  border-radius: 8px;
  align-content: center;
  align-items: center;
  min-width: 30px;
  text-align: left;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.btnShare img {
  width: 30px;
  height: 30px;
}
